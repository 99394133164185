<template>
  <div>
    <div id="writing-container">

      <div>
        <textarea
          v-model="achievement"
          name="achievement"
          id="achievement-textarea"
          cols="20"
          rows="10"
          placeholder="Tell us about your awesomeness 🤩"
        ></textarea>
      </div>

      <div>
        <div id="btn-wrapper">
          <button id="cancel-btn" @click="cancel">Cancel</button>
          <button id="send-btn" @click="sendAchievement">Send!</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "WritingArea",
  data() {
    return {
      achievement: "",
    };
  },

  methods: {
    sendAchievement() {
      this.$emit("emitAchievement", {
        achievement: this.achievement,
      });
    },

    cancel() {
      this.$emit("emitCancel");
    }
  },
});
</script>

<style scoped>
#writing-container {
  background-color: var(--achievement-color);
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  padding: 2rem;
  width: 400px;
  /*
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  */

  

  /*margin-right: 0.3rem;
  margin-bottom: 0.3rem;*/
  /*
  
  border: 0.15rem solid var(--btn-color);
  */
  border-radius: 16px;
  
  -webkit-box-shadow: 0px 0px 8px var(--background-color);
  -moz-box-shadow: 0px 0px 8px var(--background-color);
  box-shadow: 0px 0px 8px var(--background-color);
  
}

#name-label, #achievement-label {
    display: block;
    width: 100%;
}

#name-input, #achievement-textarea, #send {
    display: block;
    width: 100%;
}

#name-input, #achievement-textarea {
    border-radius: 0.375rem;
}

#achievement-textarea {
  color: var(--background-color);
  font-size: 1.25rem;
  resize: none;
  max-width: -moz-available;
}

#achievement-textarea::placeholder {
  color: hsl(244, 61%, 50%);
  font-size: 1.25rem;
}


:is(#send-btn, #cancel-btn):hover  {
  border: 0.15rem solid var(--btn-color-hover);
  background-color: var(--btn-color-hover);
}

:is(#send-btn, #cancel-btn):active {
  border: 0.15rem solid var(--btn-color-active);
  background-color: var(--btn-color-active);
}

#send-btn, #cancel-btn {
  color: var(--background-color);
  font-size: 1.0rem;
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
  border-radius: 16px;
  height: 50px;
  margin: 0 1rem;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
}

#btn-wrapper {
  display: flex;
  justify-content: end;
}

@media (max-width: 500px) {
  #writing-container {
    padding: 1rem;
    width: 70vw;
  }

  #btn-wrapper {
    flex-flow: column-reverse;
    align-content: center;
  }

  #cancel-btn {
    margin-top: 1rem;
  }

}
</style>