<template>
  <div class="highfive-main">
    <div class="achievements-wrapper">
      <Achievements
        id="achievements"
        v-bind:achievements="documents"
        v-on:likeAchievement="likeAchievement"
        v-on:dislikeAchievement="dislikeAchievement"
        v-on:deleteAchievement="deleteAchievement"
        v-on:reportAchievement="openReportingModal"
      />

      <ReportingModal 
        id="reporting-modal"
        v-if="showReportingModal"
        v-bind:achievement="achievementToReport"
        v-on:reportAchievement="reportAchievement"
        v-on:emitCancel="closeReportingModal"
      />

      <div
        id="btn-wrapper-bottom"
        class="btn-wrapper"
        v-if="!isHeaderInViewport"
        style="sticky"
      >
        <button id="open-modal-btn-bottom" @click="toggleModal()">
          <img src="../assets/iconmonstr-plus-2-24-purple.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Achievements from "./Achievements.vue";
import ReportingModal from "./ReportingModal.vue";
import "firebase/database";
import "firebase/firestore";
import { db, getUserImage, getUserImages } from "../firebase/db.js";
import { useUserStore } from '../stores/user';

export default {
  name: "HighFiveMain",
  components: {
    Achievements,
    ReportingModal,
  },

  data() {
    return {
      documents: this.achievements,
      showModal: false,
      store: useUserStore(),
      storageRef: null,
      iconUrl: null,
      componentLoaded: false,
      isHeaderInViewport: true,
      isLoading: false,
      testing: false,
      showReportingModal: false,
      achievementToReport: null,
    };
  },

  props: ["loadCounter", "achievements"],

  created() {
    this.getInitialItems();

    function debounce(method, delay) {
      clearTimeout(method._tId);
      method._tId = setTimeout(function () {
        method();
      }, delay);
    }

    document.addEventListener(
      "scroll",
      (ev) => {
        debounce(() => {
          this.checkIfHeaderIsInViewport();
          this.checkIfLastAchievementIsInViewport();
        }, 1000);
      },
      true
    );
  },

  destroyed() {
    document.removeEventListener("scroll", () => {
      console.log("removed");
    });
  },

  mounted() {
    

    this.componentLoaded = true;
  },

  watch: {
    achievements() {
      this.documents = this.achievements;
    },

    loadCounter() {
      this.getMoreItems();
    },
  },

  methods: {
    getInitialItems() {
      this.isLoading = true;

      db.ref("Achievements")
        .orderByChild("date")
        .limitToLast(20)
        .once("value")
        .then((snapshot) => {
          const keys = Object.keys(snapshot.val());
          // this.referenceToOldestKey = [...keys].sort().reverse()[keys.length - 1];

          const values = Object.values(snapshot.val());
          for (let i = 0; i < values.length; i++) {
            values[i].id = keys[i];
          }

          getUserImages(values.reverse(), this.store).then((docsWithImages) => {
            this.documents = docsWithImages.filter((item) => !item.reported);
            this.isLoading = false;
          });
        });
    },

    getMoreItems() {
      this.isLoading = true;

      db.ref("Achievements")
        .orderByChild("date")
        .endBefore(this.documents[this.documents.length - 1].date)
        .limitToLast(20)
        .once("value")
        .then((snapshot) => {
          if (snapshot.val() === null || snapshot.val() === undefined) {
            return;
          }

          const keys = Object.keys(snapshot.val());
          // this.referenceToOldestKey = [...keys].sort().reverse()[keys.length - 1];

          const values = Object.values(snapshot.val());
          for (let i = 0; i < values.length; i++) {
            values[i].id = keys[i];
          }

          getUserImages(values.reverse(), this.store).then((docsWithImages) => {
            this.documents = this.documents.concat(
              docsWithImages.filter((item) => !item.reported)
            );
            console.log(this.documents);
            this.isLoading = false;
          });
        });
    },

    allImagesLoaded() {
      return this.documents.every((el) => {
        return el.userIcon !== undefined;
      });
    },

    likeAchievement(achievement) {
      const upvotesRef = db.ref(`Achievements/${achievement.id}/likes`);
      upvotesRef.transaction((likes) => {
        return (likes || 0) + 1;
      });
      this.updateUser();
    },

    dislikeAchievement(achievement) {
      const upvotesRef = db.ref(`Achievements/${achievement.id}/likes`);
      upvotesRef.transaction((likes) => {
        return (likes || 0) - 1;
      });
      this.updateUser();
    },

    deleteAchievement(achievement) {
      this.documents = this.documents.filter((item) => item !== achievement);
      db.ref("Achievements").child(achievement.id).remove();
    },

    updateAchievement(achievement) {
      db.ref(`Achievements/${achievement.id}`).update(achievement)
    },

    updateUser() {
      db.ref('Users/' + this.store.user.uid).update(this.store.user)
    },

    openReportingModal(achievement) {
      this.achievementToReport = achievement
      this.showReportingModal = true
    },

    reportAchievement(achievement) {
      this.showReportingModal = false;
      this.achievementToReport = null;

      achievement.reported = true;
      achievement.reportedBy = this.store.user.uid;
      this.updateAchievement(achievement);

      this.store.user.reportedAchievements.push(achievement.id)
      this.updateUser()

      this.documents = this.documents.filter((item) => item !== achievement);
      console.log(
        `Achievement ${achievement.id} got reported by user ${this.store.user.uid}`
      );
    },

    closeReportingModal() {
      this.achievementToReport = null;
      this.showReportingModal = false;
    },

    signUp() {
      document.getElementById("sign-up").hidden = false;
    },

    getUserImageUrl() {
      getUserImage(this.store.user.uid, this.store).then((url) => {
        this.iconUrl = url;
      });
    },

    checkIfHeaderIsInViewport() {
      if (!this.componentLoaded) {
        return true;
      }
      const elem = document.getElementById("btn-wrapper");
      var bounding = elem.getBoundingClientRect();

      const isVisible =
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      this.isHeaderInViewport = isVisible;
    },

    checkIfLastAchievementIsInViewport() {
      if (!this.componentLoaded) {
        return true;
      }
      const elems = document.getElementsByClassName("achievement");
      var bounding = elems[elems.length - 1].getBoundingClientRect();

      const isVisible =
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (isVisible && !this.isLoading) {
        this.getMoreItems();
      }
    },
  },

  /*
  firebase: {
    Achievements: {
    documents: db.ref("Achievments"),
    source: db.ref("Achievments"),
    asObject: false,
    readyCallback(snapshot){ console.log(snapshot.val()) },
    cancelCallback(error){ console.log('error', error) }
    }
  },
  */
};
</script>

<style scoped>
#highfive-main {
  display: flex;
  flex-flow: column;
}

#achievements {
  /* height: 70vh; */
  overflow-y: auto;
}

#open-modal-btn, #open-modal-btn-bottom {
  color: white;
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
  border-radius: 9999px;
  width: 50px;
  height: 50px;
  margin: 0 1rem;
  border: 0.15rem solid var(--btn-color);
}

#open-modal-btn-bottom {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

:is(#open-modal-btn, #open-modal-btn-bottom, #sign-up-btn, #sign-in-btn, #sign-out-btn):hover {
  border: 0.15rem solid white;
  background-color: var(--btn-color-hover);
}

:is(#open-modal-btn, #open-modal-btn-bottom, #sign-up-btn, #sign-in-btn, #sign-out-btn):active {
  border: 0.15rem solid white;
  background-color: var(--btn-color-active);
}

#sign-up-btn, #sign-in-btn, #sign-out-btn {
  color: hsl(245, 62%, 15%);
  font-size: 1rem;
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
  border-radius: 16px;
  height: 50px;
  margin: 0 1rem;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
}

/*
color: white;
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
  border-radius: 9999px;
  width: 50px;
  height: 50px;
  margin: 0 1rem;
*/

#btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#btn-wrapper-bottom {
  display: flex;
  justify-content: end;
  bottom: 102px;
  position: sticky;
}

#sign-up {
  color: white;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid white;
}

#sign-in {
  color: white;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#achievements {
  margin: 0 10%;
  width: 100%;
}

.achievements-wrapper {
  /* position: relative; */
  display: flex;
  justify-content: center;
}

#reporting-modal {
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

@media (max-width: 955px) {
  #btn-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  #achievements {
    margin: 0px 0px;
  }
}
</style>
