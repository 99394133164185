<template>
  <div>
    <div class="wrapper">
      <div
        id="profile-wrapper"
        style="padding: 2rem 10%"
      >
        <div id="horizontal-flex-wrapper">
          <div id="user-data" style="color: white">
            <div id="user-icon-btn-large-background">
            <button
              id="user-icon-btn-large"
              :style="{
                backgroundImage: 'url(' + this.store.userIdToImageUrl.get(this.$route.params.userId) + ')',
                backgroundRepeat: 'no-repeat',
              }"
              @click="openUploadModal()"
            ></button>
            </div>
          </div>

          <button
            id="delete-profile-button"
            v-if="store.user.uid === this.$route.params.userId"
            @click="deleteProfile()"
          >
            delete profile
          </button>
        </div>
      </div>
    </div>

    <div style="position: relative" class="achievements-wrapper">
      <Achievements
        id="achievements"
        v-bind:achievements="documents"
        v-on:deleteAchievement="deleteAchievement"
      />
    </div>

    <input
      type="file"
      ref="upload"
      style="display: none"
      accept="image/png, image/jpeg"
      @change="handleFileSelect"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import "firebaseui/dist/firebaseui.css";
import { db, getUserImage, getUserImages } from "../firebase/db.js";
import Achievements from "./Achievements.vue";
import firebase from "firebase";
import { useUserStore } from "../stores/user.js";

export default Vue.extend({
  name: "UserProfile",
  components: {
    Achievements,
  },
  data() {
    return {
      store: useUserStore(),
      documents: this.achievements,
      storageRef: null,
      testing: false,
    };
  },

  props: ["achievements"],

  created() {
    // TODO
    /*
    if (!store.user || !store.user.uid) {
        store.user = firebase.auth().currentUser
    }
    console.log('mounted user', store.user)
    */

    this.getUserImageUrl();
    this.getAchievements();
  },

  mounted() {
    // TODO
    /*
    if (!store.user || !store.user.uid) {
        store.user = firebase.auth().currentUser
    }
    console.log('mounted user', store.user)
    */

    var storage = firebase.storage();
    this.storageRef = storage.ref().child("images");
  },

  watch: {
    achievements() {
      console.log(this.achievements);
      this.documents = this.achievements;
    },
  },

  methods: {
    getUserImageUrl() {
      getUserImage(this.store.user.uid, this.store).then((url) => {
        this.store.iconUrl = url
      });
    },

    openUploadModal() {
      this.$refs.upload.click();
    },

    async handleFileSelect(event) {
      const file = event.target.files[0];
      const type = file.type.split("/").pop();

      var metadata = {
        contentType: `image/${type}`,
      };

      this.storageRef
        .child(`${this.store.user.uid}`)
        .listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            itemRef
              .delete()
              .then(() => {})
              .catch(() => {});
          });
        })
        .catch(() => {});

      const ref = this.storageRef.child(`${this.store.user.uid}/icon.${type}`);
      ref
        .put(file, metadata)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            this.store.userIdToImageUrl.delete(this.store.user.uid);
            this.store.iconUrl = url;
            this.getAchievements();
          });
        })
        .catch(() => {
          window.alert("Upload failed. Please try again later");
        });
    },

    getAchievements() {
      const indexOfProfile = this.$route.path.indexOf("profile");
      const userProfileId = this.$route.path.substring(
        indexOfProfile + "profile/".length
      );

      db.ref("Achievements")
        .orderByChild("userId")
        .equalTo(userProfileId)
        .once("value")
        .then((snapshot) => {
          const keys = Object.keys(snapshot.val() || []);
          const values = Object.values(snapshot.val() || []);

          for (let i = 0; i < values.length; i++) {
            values[i].id = keys[i];
          }

          getUserImages(values.reverse(), this.store).then((docsWithImages) => {
            if (userProfileId === this.store.user.uid) {
              this.documents = docsWithImages;
            } else {
              this.documents = docsWithImages.filter((item) => !item.reported);
            }
          });
          
        });
    },

    deleteAchievement(achievement) {
      console.log("before", this.documents.length);
      this.documents = this.documents.filter((item) => item !== achievement);
      console.log("after", this.documents.length);
      db.ref("Achievements").child(achievement.id).remove();
    },

    deleteProfile() {
      for (let i = 0; i < this.documents.length; i++) {
        db.ref("Achievements").child(this.documents[i].id).remove();
        this.documents = this.documents.filter(
          (item) => item !== this.documents[i]
        );
      }

      firebase
        .auth()
        .currentUser.delete()
        .then(() => {
          console.log(`User ${this.store.user.uid} deleted successfully`);
        })
        .catch((error) => {
          console.log(`User ${this.store.user.uid} could not be deleted`, error);

          /*
           const credential = promptForCredentials();

          user.reauthenticateWithCredential(credential).then(() => {
            // User re-authenticated.
          }).catch((error) => {
            // An error ocurred
            // ...
          });
           */
        });
    },
  },
});
</script>


<style scoped>
#horizontal-flex-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#vertical-flex-wrapper {
  display: flex;
}

.achievements-wrapper {
  /* position: relative; */
  display: flex;
  justify-content: center;
}

#achievements {
  overflow: auto;
  margin: 0 10%;
  width: 100%;
}

@media (max-width: 500px) {
  #achievements {
    margin: 0px 0px;
  }
}

#user-icon-btn-large-background {
  width: 99px;
  height: 99px;
  border-radius: 9999px;
  background-color: var(--background-color);
  margin: 0 1rem;
  float: left;
}

#user-icon-btn-large {
  width: 100px;
  height: 100px;
  border-radius: 9999px;
  background-size: 100px 100px;
  display: block;

  border: 5px;
  outline: 5px solid var(--achievement-color);
}

#user-icon-btn-large:hover {
  opacity: 0.9;
}

#user-icon-btn-large:active {
  opacity: 0.7;
}

#profile-wrapper {
  background-color: var(--achievement-color);
  overflow-y: auto;
  overflow-x: none;

  height: -webkit-fit-content;
  height: -moz-fit-content;

  border-radius: 16px;

  padding: 1rem;
  padding-top: 35px;
}

#delete-profile-button {
  font-size: 1rem;
  border-radius: 16px;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
  color: var(--btn-color);
  border: 0.15rem solid var(--background-color);
  background-color: var(--background-color);
  margin-top: 1rem;
  /* transform: translate(0%, -100%); */
}

#delete-profile-button:hover {
  border: 0.15rem solid white;
  background-color: var(--background-color-hover);
}

#delete-profile-button:active {
  border: 0.15rem solid white;
  background-color: var(--background-color-active);
}

@media (min-width:820px) {
  #delete-profile-button {
    margin-top: 0rem;
    align-self: flex-end;
  }
}
</style>