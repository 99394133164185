<template>
  <div>
    <div id="btn-wrapper" style="padding: 2rem 10%">
      <div>
        <h1>
          <a href="/" style="color: var(--btn-color); text-decoration: none">
            The High Five Network
          </a>
        </h1>
      </div>

      <div style="display: flex; align-items: center">
        <button v-if="testing" @click="getMoreItems()">
          <img
            src="../assets/iconmonstr-plus-2-24-purple.png"
            alt="Add an achievement"
            width="24"
            height="24"
          />
        </button>

        <button
          v-if="store.user"
          id="open-modal-btn"
          @click="openCloseWritingArea()"
        >
          <img
            src="../assets/iconmonstr-plus-2-24-purple.png"
            alt="Add an achievement"
            width="24"
            height="24"
          />
        </button>

        <!--
    <button
      id="sign-up-btn" 
      v-if="!signedIn"
      @click="signUp()"
    >
      Sign Up
    </button>
    -->
        <button
          v-if="!store.user"
          id="sign-in-btn"
          @click="showSignInComponent()"
          alt="Sign in"
        >
          Sign In
        </button>

        <button
          v-if="store.user"
          id="sign-out-icon-btn"
          @click="signOut()"
          alt="Sign out"
        >
          <img
            src="../assets/iconmonstr-log-out-10-24-sign-out.png"
            alt="Sign out"
            width="24"
            height="24"
          />
        </button>

        <button
          v-if="store.user"
          id="sign-out-btn"
          @click="signOut()"
          alt="Sign out"
        >
          Sign Out
        </button>
        <router-link
          v-if="store.user"
          :to="{ name: 'profile', params: { userId: store.user.uid } }"
        >
          <button
            id="user-icon-btn"
            @click="showProfile()"
            :style="{ backgroundImage: 'url(' + store.iconUrl + ')' }"
            alt="Go to your profile page"
          />
        </router-link>
      </div>
    </div>
    <SignIn id="sign-in" hidden="true" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { getUserImage } from "../firebase/db.js";
import firebase from "firebase";
import SignIn from "../components/SignIn.vue";
import { useUserStore } from '../stores/user'

export default Vue.extend({
  name: "Header",
  components: {
    SignIn,
  },
  data() {
    return {
      store: useUserStore(),
      iconUrl: null,
      openSignInComponent: true,
    };
  },

  props: ["testing"],

  methods: {
    getUserImageUrl() {
      getUserImage(this.store.user.uid, this.store).then((url) => {
        this.iconUrl = url;
      });
    },

    signOut() {
      firebase.auth().signOut();
      this.$router.push("/");
      this.store.user = undefined;
    },

    showSignInComponent() {
      if (window.localStorage.getItem("cookiesAccepted") === "true") {
        document.getElementById("sign-in").hidden = false;
      } else {
        document.getElementById("cookie-consent").style.display = "block";
      }
    },

    getMoreItems() {
      this.$emit("getMoreItems");
    },

    openCloseWritingArea() {
      this.$emit("openCloseWritingArea");
    },

    showProfile() {
      if (this.store.user) {
        this.$router.push(`/profile/${this.store.user.uid}`);
      }
    },
  },
});
</script>

<style lang="scss">
#open-modal-btn,
#open-modal-btn-bottom,
#sign-out-icon-btn {
  color: white;
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
  border-radius: 9999px;
  width: 50px;
  height: 50px;
  margin: 0 1rem;
  border: 0.15rem solid var(--btn-color);
}

#sign-out-btn {
  display: none;
}

:is(#open-modal-btn, #open-modal-btn-bottom, #sign-up-btn, #sign-in-btn, #sign-out-btn):hover {
  border: 0.15rem solid white;
  background-color: var(--btn-color-hover);
}

:is(#open-modal-btn, #open-modal-btn-bottom, #sign-up-btn, #sign-in-btn, #sign-out-btn):active {
  border: 0.15rem solid white;
  background-color: var(--btn-color-active);
}

#sign-up-btn,
#sign-in-btn,
#sign-out-btn {
  color: hsl(245, 62%, 15%);
  font-size: 1rem;
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
  border-radius: 16px;
  height: 50px;
  margin: 0 1rem;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
}

#btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#sign-up {
  color: white;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid white;
}

#sign-in {
  color: white;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 955px) {
  #btn-wrapper {
    flex-direction: column;
  }
}

@media (min-width: 410px) {
  #sign-out-btn {
    display: unset;
  }

  #sign-out-icon-btn {
    display: none;
  }
}
</style>
