<template>
  <div>
    <div id="achievements-container">
      <div
        class="achievement-container"
        v-for="achievement in achievements"
        :key="achievement.id"
      >
        <div class="user-image-wrapper">
          <img
            class="user-image rounded-full mx-auto"
            :src="getContent(achievement)"
            @click="visitUserProfile(achievement.userId)"
            alt="Visit the profile of this user"
            width="50"
            height="50"
          />
        </div>
        

        <div 
          class="achievement" 
          :class="{ reported: achievement.reported }"
          :title="getTitle(achievement)"
        >
          <div>
            <div class="achievement-text">
              {{ achievement.achievement }}
            </div>
            
            <button
              class="upvote-btn"
              title="Give a high five!!!"
              alt="Give this achievement a high five"
              v-if="achievement.achievement !== undefined"
              @click="likeOrDislikeAchievement(achievement)"
              :disabled="isOwnAchievement(achievement)"
              :class="{ liked: store.user !== undefined && store.user.likedAchievements.includes(achievement.id) }"
            >
              <span>🙌 {{ achievement.likes }}</span>
            </button>

            <button
              class="delete-btn"
              title="Delete your achievement"
              alt="Delete this achievement"
              v-if="achievement.achievement !== undefined && isLoggedIn() && isOwnAchievement(achievement)"
              @click="deleteAchievement(achievement)"
              :disabled="!isOwnAchievement(achievement)"
            >
              <span>
                <img src="../assets/iconmonstr-trash-can-27-24-purple.png" alt="Delete this achievement" width="24" height="24">
              </span>
            </button>

            <button
              class="report-btn"
              title="Report this achievement"
              alt="Report this achievement"
              v-if="achievement.achievement !== undefined && isLoggedIn && !isOwnAchievement(achievement)"
              @click="reportAchievement(achievement)"
            >
              <span>
                <img src="../assets/iconmonstr-stop-3-24-purple.png" alt="Report this achievement" width="24" height="24">
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { useUserStore } from "../stores/user";

export default Vue.extend({
  name: "Achievements",
  data() {
    return {
      storageRef: null,
      userIdToIcon: new Map(),
      store: useUserStore(),
      // userIdToImageUrl: store.userIdToImageUrl,
    };
  },

  props: ["achievements"],

  methods: {
    isLoggedIn() {
      return this.store.user !== undefined
    },

    getTitle(achievement) {
      if (achievement.reported) {
        return "This achievement has been reported and is not shown publicly anymore."
      }

      return ""
    },
    
    isOwnAchievement(achievement) {
      return !this.store.user || this.store.user.uid === achievement.userId;
    },

    getContent(achievement) {
      return achievement.userIcon;
    },

    visitUserProfile(userId) {
      if (this.$route.path === `/profile/${userId}`) {
        location.reload()
      } else {
        this.$router.push(`/profile/${userId}`)
      }
    },

    likeOrDislikeAchievement(achievement) {
      if (this.store.user.likedAchievements.includes(achievement.id)) {
        this.store.user.likedAchievements = this.store.user.likedAchievements.filter(a => a !== achievement.id)
        achievement.likes--;
        this.$emit("dislikeAchievement", achievement);
        return
      }
      this.store.user.likedAchievements.push(achievement.id)
      achievement.likes++;
      this.$emit("likeAchievement", achievement);
    },

    deleteAchievement(achievement) { 
      this.$emit("deleteAchievement", achievement);
    },

    reportAchievement(achievement) {
      this.$emit("reportAchievement", achievement);
    }
  },

});
</script>

<style lang="scss">
#achievements-container {
  background-color: var(--background-color);

  display: flex;
  flex-flow: column;

  /*
  display: flex;
  flex-flow: wrap;
  align-content: stretch;
  justify-content: center;
  overflow-y: auto;
  */
  /* display: grid;
  grid-template-columns: 1fr;
  justify-content: center; */
}

.achievement-container:nth-child(even) {
  align-self: start;
  -webkit-align-self: flex-start; /* add prefixed version */
  -ms-flex-item-align: start; /* add prefixed version */

  .user-image {
    left: 0px;
  }

  .user-image-wrapper {
    left: -20px;
  }

  .upvote-btn {
    left: 40px;
  }

  .delete-btn {
    left: 100px;
  }

  .report-btn {
    left: 100px;
  }

  .achievement {
    padding-left: 50px;
  }
}

.achievement-container:nth-child(odd) {
  align-self: end;
  -webkit-align-self: flex-end; /* add prefixed version */
  -ms-flex-item-align: end; /* add prefixed version */

  .user-image {
    right: 0px;
  }

  .user-image-wrapper {
    right: -20px;
  }

  .upvote-btn {
    right: 40px;
  }

  .delete-btn {
    right: 100px;
  }

  .report-btn {
    right: 100px;
  }

  .achievement {
    padding-right: 50px;
  }
}

.achievement-container {
  margin-top: 50px;
  margin-right: 50px;
  margin-left: 50px;
  /*
  margin: 15px;
  width: 200px;
  */
  /*  height: fit-content; */

  // overflow: hidden;

  /*
  border-radius: 16px;
  border: 0.15rem solid rgb(185, 12, 119);
  -webkit-box-shadow: 0px 0px 8px rgb(185, 12, 119);
  -moz-box-shadow: 0px 0px 8px rgb(185, 12, 119);
  box-shadow: 0px 0px 8px rgb(185, 12, 119);
*/

  /*
  height: fit-content;
  margin-top: 1rem;
  border: 1px solid black;
  */
  display: flex;
  flex-flow: wrap;

  position: relative;
  width: 70%;
}

.name {
  background-color: hsl(245, 62%, 90%);
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.achievement {
  background-color: var(--achievement-color);
  overflow-y: auto;
  overflow-x: none;

  height: -webkit-fit-content;
  height: -moz-fit-content;
  max-height: 200px;

  width: 100%;

  // position: relative;
  //min-height: 50px;
  // z-index: 0;

  display: flex;

  border-radius: 16px;

  padding: 1rem;
  padding-top: 35px;
}

.achievement.reported {
  background-color: var(--achievement-reported-color);
}

.user-image {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  // z-index: 1;
}

.user-image-wrapper {
  position: absolute;
  top: -20px;
  width: 49px;
  height: 49px;
  border-radius: 9999px;
  background-color: var(--background-color);
  // z-index: 1;
}

.user-image:hover {
  opacity: 0.9;
}

.user-image:active {
  opacity: 0.7;
}

.achievement-text {
  white-space: pre-wrap;
}

.upvote-btn, .delete-btn, .report-btn {
  position: absolute;
  top: -20px;
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  border: none;
  background-color: #e8e8e8; // f0f0f0
}

:is(.upvote-btn, .delete-btn, .report-btn):hover {
  border: 0.15rem solid white;
  background-color: var(--btn-color-hover);
}

:is(.upvote-btn, .delete-btn, .report-btn):active {
  border: 0.15rem solid white;
  background-color: var(--btn-color-active);
}

.liked {
  background-color: var(--liked-color);
  border: 0.15rem solid white;
}

.liked:hover {
  background-color: var(--liked-color-hover);
}

.liked:active {
  background-color: var(--liked-color-active);
}

@media (max-width: 500px) {
  .achievement-container {
    width: 65%;
  }
}
</style>

// #e1d4f7 light
// #6828d7 medium
// #15082b dark

display: flex;
  flex-wrap: wrap;
  div {
    height: 150px;
    line-height: 150px;
    background: #EC985A;
    color: white;
    margin: 0 1rem 1rem 0;
    text-align: center;
    font-family: system-ui;
    font-weight: 900;
    font-size: 2rem;
    flex: 1 0 auto;
  } 
  @for $i from 1 through 36 { 
    div:nth-child(#{$i}) {
      $h: (random(400) + 70) + px;
      width: $h;
    }
  }