<template>
<!-- <v-app>
  <v-content>
    <HighFiveMain/>
  </v-content>
</v-app> -->
<div id="app" style="display: flex; min-height: 100vh; flex-direction: column; justify-content: space-between;">
    <Header
      v-bind:testing="testing"
      v-on:openCloseWritingArea="openCloseWritingArea"
      v-on:getMoreItems="getMoreItems"
    />
    <WritingArea
      id="writing-area"
      v-if="showWritingArea"
      v-on:emitAchievement="addItem"
      v-on:emitCancel="close"
    />
    <router-view
      :key="$route.fullPath"
      v-bind:loadCounter="loadCounter"
      v-bind:achievements="documents"
    ></router-view>

    
    <Footer 
      id="footer"
    />

    <CookieConsent v-if="showCookieConsent && this.$route.name !== 'datenschutz'" />
  </div>
  
</template>

<script>
//import HighFiveMain from './components/HighFiveMain.vue'
import Header from './components/Header.vue'
import WritingArea from './components/WritingArea.vue'
import CookieConsent from './components/CookieConsent.vue'
import Footer from './components/Footer.vue'

import { db, getUserImages } from "./firebase/db.js";
import { v4 as uuidv4 } from "uuid";
import { useUserStore } from './stores/user'

export default {
  name: 'App',
  components: {
   // HighFiveMain 
   Header,
   WritingArea,
   CookieConsent,
   Footer
  },

  data() {
    return {
      store: useUserStore(),
      testing: false,
      showWritingArea: false,
      loadCounter: 0,
      showCookieConsent: false,
      documents: [],
    };
  },

  created() {
    document.title = "The High Five Network";

    const html = document.documentElement
    html.setAttribute('lang', 'en')

    if (window.localStorage.getItem('cookiesAccepted') == null || window.localStorage.getItem('cookiesAccepted') === 'false' ) {
      this.showCookieConsent = true;
    }
  },

  methods: {
    openCloseWritingArea() {
      this.showWritingArea = !this.showWritingArea;
    },

    async addItem(value) {
      this.showWritingArea = false;

      await db.ref("Achievements").push({
        id: uuidv4(),
        userId: this.store.user.uid,
        achievement: value.achievement,
        likes: 0,
        reported: false,
        date: Date.now(),
      });

      if (this.$route.name === 'home') {
        this.getInitialItems();
      }

      if (this.$route.name === 'profile') {
        this.getAchievementsOfUser();
      }
      
    },

    getInitialItems() {
      db.ref("Achievements")
        .orderByChild("date")
        .limitToLast(7)
        .once("value")
        .then((snapshot) => {
          const keys = Object.keys(snapshot.val());
          // this.referenceToOldestKey = [...keys].sort().reverse()[keys.length - 1];

          const values = Object.values(snapshot.val());
          for (let i = 0; i < values.length; i++) {
            values[i].id = keys[i];
          }

          getUserImages(values.reverse(), this.store).then((docsWithImages) => {
            this.documents = docsWithImages.filter(item => !item.reported);
            this.isLoading = false;
          });
        });
    },

    getAchievementsOfUser() {
      db.ref("Achievements")
        .orderByChild("userId")
        .equalTo(this.store.user.uid)
        .once("value")
        .then((snapshot) => {
          const keys = Object.keys(snapshot.val());
          const values = Object.values(snapshot.val());

          for (let i = 0; i < values.length; i++) {
            values[i].id = keys[i];
          }

          getUserImages(values.reverse(), this.store).then((docsWithImages) => {
            this.documents = docsWithImages;
          });
          console.log("this.documents", this.documents);
        });
    },

    close() {
      this.showWritingArea = false;
    },

    getMoreItems() {
      this.loadCounter++;
    },

  }
}
</script>

<style>
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  /* unicode-range: U+000-5FF; */
  src: local("Roboto"), url("/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'Praise';
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  /* unicode-range: U+000-5FF; */
  src: local("Praise"), url("/fonts/Praise/Praise-Regular.ttf") format("truetype");
  font-display: swap;
}

html.overflow-y-hidden {
  overflow-y: auto !important;
}

* {
  --background-color: hsl(244, 61%, 15%);  
  --background-color-hover: hsl(245, 62%, 21%);
  --background-color-active: hsl(245, 62%, 25%);

  --achievement-color: hsl(245, 62%, 80%);
  --achievement-reported-color: hsl(245, 62%, 88%);

  --liked-color: hsl(245, 62%, 80%);
  --liked-color-hover: hsl(245, 62%, 70%);
  --liked-color-active: hsl(245, 62%, 60%);

  --btn-color: 	hsl(0, 0%, 91%);
  --btn-color-hover: 	hsl(0, 0%, 85%);
  --btn-color-active: 	hsl(0, 0%, 80%);
}

#app {
  font-family: "Roboto", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--background-color);
}

button { 
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
}

body { 
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  background-color: hsl(245, 62%, 15%);
}

.wrapper {
  margin: 2rem 10%;
}

html {
  height: 100%;
  width: 100%;
  background-color: hsl(245, 62%, 15%);
}

#user-icon-btn {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  border: 0px;
  background-size: 50px 50px;
  margin: 0 1rem;
}

#user-icon-btn:hover {
  opacity: 0.9;
}

#user-icon-btn:active {
  opacity: 0.7;
}

#footer {
  padding: 1rem 10%;
  position: sticky;
  bottom: 0px;
  background-color: var(--background-color);
  color: var(--btn-color);
}

h1 {
  all: unset;
  color: var(--btn-color);
  font-family: Praise;
  font-size: 60px;
}

h2 {
    color: var(--btn-color);
    margin: 2rem 0;
}

#writing-area {
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}
</style>
