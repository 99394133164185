import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: {
        uid: undefined,
        likedAchievements: [],
        reportedAchievements: []
      },
      iconUrl: undefined,
      userIdToImageUrl: new Map()
    }
  },
})

/*
export default {
  computed: {
    // note we are not passing an array, just one store after the other
    // each store will be accessible as its id + 'Store'
    ...mapStores(useUserStore)
  },
}
*/