import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage'; 

export const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
};

export const db = firebase
  .initializeApp(firebaseConfig)
  .database();

// firebase.analytics(); // TODO: How to use it

var storage = firebase.storage();
var storageRef = storage.ref().child("images");

export function getUserImages(documents, store) {
  return Promise.all(
    documents.map((document) => {
      return new Promise((resolve, reject) => {

        if (store.userIdToImageUrl.has(document.userId)) {
          console.log('image found in store for user', document.userId, store.userIdToImageUrl.get(document.userId))
          document["userIcon"] = store.userIdToImageUrl.get(document.userId)
          resolve(document)
        } else {
          console.log('image not found in store')
        }

        storageRef
          .child(`${document.userId}`)
          .listAll()
          .then((res) => {
            if (res.items.length == 0) {
              store.userIdToImageUrl.set(document.userId, require(`../assets/no-user-image.png`))
              document["userIcon"] = require(`../assets/no-user-image.png`);
              resolve(document);
            }
            res.items.forEach((itemRef) => {
              itemRef
                .getDownloadURL()
                .then((url) => {
                  store.userIdToImageUrl.set(document.userId, url)
                  document["userIcon"] = url;
                  resolve(document);
                })
                .catch(() => {
                  store.userIdToImageUrl.set(document.userId, require(`../assets/no-user-image.png`))
                  document[
                    "userIcon"
                  ] = require(`../assets/no-user-image.png`);
                  resolve(document);
                });
            });
          })
          .catch(() => {
            store.userIdToImageUrl.set(document.userId, require(`../assets/no-user-image.png`))
            document["userIcon"] = require(`../assets/no-user-image.png`);
            resolve(document);
          });
      });
    })
  );
}

export function getUserImage(userId, store) {

  console.log('getUserImage', userId)

  return new Promise((resolve, reject) => {

    if (!userId) {
      resolve(require(`../assets/no-user-image.png`))
    }

    if (store.userIdToImageUrl.has(userId)) {
      console.log('image found in store for user', userId, store.userIdToImageUrl.get(userId))
      resolve(store.userIdToImageUrl.get(userId))
    } else {
      console.log('image not found in store')
    }

    storageRef.child(userId)
    .listAll()
    .then((res) => {
      if (res.items.length == 0) {
        console.log('0')
        store.userIdToImageUrl.set(userId, require(`../assets/no-user-image.png`))
        resolve(require(`../assets/no-user-image.png`))
      }
      res.items.forEach((itemRef) => {
        itemRef
          .getDownloadURL()
          .then((url) => {
            store.userIdToImageUrl.set(userId, url)
            resolve(url)
          })
          .catch(() => {
            store.userIdToImageUrl.set(userId, require(`../assets/no-user-image.png`))
            resolve(require(`../assets/no-user-image.png`))
          });
      });
    })
    .catch(() => {
      store.userIdToImageUrl.set(userId, require(`../assets/no-user-image.png`))
      resolve(require(`../assets/no-user-image.png`))
    });

  })
        

}
