import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import { rtdbPlugin } from 'vuefire'
import HighFiveMain from './components/HighFiveMain'
import UserProfile from './components/UserProfile'
import ReleaseNotes from './components/ReleaseNotes'
import Contact from './components/Contact'
import Impressum from './components/Impressum'
import Datenschutz from './components/Datenschutz'
import About from './components/About'

import { db, getUserImage } from "./firebase/db.js";
import firebase from "firebase";

import { createPinia, PiniaVuePlugin } from 'pinia'
import { useUserStore } from './stores/user'

// import "@/plugins/firebase";

Vue.use(rtdbPlugin)
Vue.use(VueRouter)
Vue.use(PiniaVuePlugin)

const pinia = createPinia()

Vue.config.productionTip = false

const routes = [
  { name: 'profile', path: '/profile/:userId', component: UserProfile },
  { name: 'home', path: '/', component: HighFiveMain },
  { name: 'release-notes', path: '/release-notes', component: ReleaseNotes },
  { name: 'contact', path: '/contact', component: Contact },
  { name: 'impressum', path: '/impressum', component: Impressum },
  { name: 'datenschutz', path: '/datenschutz', component: Datenschutz },
  { name: 'about', path: '/about', component: About },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  render: h => h(App),
  router,
  pinia
}).$mount('#app')

const store = useUserStore()

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.user = {
      uid: user.uid,
      likedAchievements: [],
      reportedAchievements: [],
    };

    db.ref("Users")
      .orderByChild("uid")
      .equalTo(user.uid)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          store.user.likedAchievements = snapshot.val()[`${store.user.uid}`].likedAchievements || []
          store.user.reportedAchievements = snapshot.val()[`${store.user.uid}`].reportedAchievements || []
        }
      });

    getUserImageUrl();
    // this.$emit("hideSignInComponent");
  } else {
    store.user = undefined;
  }
});

function getUserImageUrl() {
  getUserImage(store.user.uid, store).then((url) => {
    store.iconUrl = url;
  });
}