<template>
    <div>
        <section id="firebaseui-auth-container"></section>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import firebase from 'firebase';

export default Vue.extend({
  name: "SignIn",
  data() {
    return {};
  },

  mounted() {
        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        var uiConfig = {
            signInSuccessUrl: "/",
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID, 
                firebase.auth.EmailAuthProvider.PROVIDER_ID
            ]
        };
        ui.start("#firebaseui-auth-container", uiConfig);
    },

});
</script>


<style scoped>

</style>